import React, { useContext } from 'react'
import SideLine from '../../elements/SideLine/SideLine'
import Img from 'gatsby-image'
import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { WrapperSection } from '../../wrappers'
import {
  sBlogWrapper,
  sBlog,
  sBlogTheme,
  sTitle,
} from '../../pages/BlogsSearch/BlogsSearch'
import { string_to_slug } from '../../helpers'
import { StateContext, initialState } from '../../../context'

const Project = ({ data, theme, ...props }) => {
  const { image, title, slug_section } = data
  return (
    <Grid xs={12} sm={6} md={4} item css={sBlogWrapper}>
      <Link
        to={`/${slug_section ? slug_section : string_to_slug(title)}`}
        css={[sBlog, sBlogTheme(theme), { height: 'max-content' }]}>
        <div css={sImage}>
          {image?.extension === 'svg' ? (
            <img
              src={image?.publicURL}
              alt={image?.name + 'RDB'}
              css={[sImage.image, { transform: 'scale(0.6)' }]}
            />
          ) : (
            image?.childImageSharp?.fluid && (
              <Img style={sImage.image} fluid={image.childImageSharp.fluid} />
            )
          )}
        </div>
        <div css={sTitle}>{title}</div>
      </Link>
    </Grid>
  )
}

const ServicesProjects = ({ data, theme, ...props }) => {
  const { slug_section, title: sectionTitle } = data
  const state = useContext(StateContext)
  const language = state?.language ? state.language : initialState?.language
  const query = useStaticQuery(graphql`
    {
      project: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/uslugiProjects/" } }
      ) {
        edges {
          node {
            frontmatter {
              order
              title
              description
              subtitle
              language
              slug_section
              seo_title
              seo_desc
              image {
                name
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, toFormat: JPG) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              projects {
                title
                subtitle
                body
                # image {
                #   name
                #   publicURL
                #   childImageSharp {
                #     fluid(maxWidth: 1920, quality: 100, toFormat: JPG) {
                #       ...GatsbyImageSharpFluid
                #     }
                #   }
                # }
              }
            }
          }
        }
      }
    }
  `)

  const projects = query.project.edges.map(({ node }) => node.frontmatter)
  // console.log('here2', data)
  return (
    <div css={{ marginBottom: '10rem' }}>
      <WrapperSection
        id={slug_section}
        noPaddingTop
        noMarginTop
        outside={<SideLine theme={theme}>{sectionTitle}</SideLine>}>
        <Grid container>
          {projects
            .filter((project) => project.image)
            .filter((project) =>
              state?.language ? project.language === language : true
            )
            .sort((a, b) => a.order - b.order)
            .map((project, id) => (
              <Project key={id} data={project} theme={theme} />
            ))}
        </Grid>
      </WrapperSection>
    </div>
  )
}

export default ServicesProjects

const sImage = {
  position: 'relative',
  width: '100%',
  height: 300,
  borderRadius: '16px 16px 0 0',

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
}
